import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "about-us-1": `The company was founded by Jan Puzon who had huge experience in pest control companies in UAE. 
          Initially, it was called British Pest Control and was the first and single private pest control company in Azerbaijan. 
          Jan Puzon passed away in 2000 and from this time the company was renamed Puzon Co as a tribute to Jan Puzon.`,
          "about-us-2": `Puzon Co specializes in pest control services in commercial, industrial and residential premises in Azerbaijan. 
          The service concept combines quality, value and convenience with environmentally responsible pest control techniques.`,
          "about-us-3": `We apply all required standards of WHO and HACCP (Hazard analysis and critical control points). 
          To maintain a healthy environment, we implement IPM program.`,
          "about-us-4": `With more than 25 years Puzon Co is committed to the promise of total customer satisfaction. 
          This includes quick response time, environmentally responsible programs, detailed and customized service.`,
          "about-us-5": `We have a 24 hours answering service. With Puzon Co, you are totally protected, 
          because everything our experienced and friendly pest control operators do for you is insured and guaranteed.`,
          "our-customers": `Our respectful customers`,
          "our-certificates": `Our certificates`,
          "quote-1": `Excellence is a norm in everything that we do.`,
          "quote-2": `Honesty and integrity is at the core of all our actions.`,
          "quote-3": `Jan Puzon`,
          "our-services": `Scope of Our Services`,
          services: [
            {
              title: 'Disinsection',
              text: 'Destructive and preventive actions for controlling all kinds of insects and arthropod pests: cockroaches, ants, beetles, wasps, fleas, flies, mosquitoes, bugs, spiders, scorpions.'
            },
            {
              title: 'Deratization',
              text: 'Eradication of rodents that are the sources of communicable diseases and not only. In many cases, they also can cause economic losses.'
            },
            {
              title: 'Disinfection Covid 19',
              text: 'Long-lasting, environmentally friendly sanitisation that eliminates bacteria and viruses such as SARS-CoV2(COVID 19) and H1N1(the flu).'
            },
            {
              title: 'Fumigation',
              text: 'The process of using chemical smoke or vapor to kill a variety of pests and rodents in places. Recommended to businesses involved in global trading. '
            },
            {
              title: 'Snake Control',
              text: 'Prevention and removal of snakes and other kinds of reptiles.'
            },
            {
              title: 'Feral and Wildlife Animals',
              text: 'Animals Control - removal of any species that causing the problem, danger and damage of the property.'
            },
            {
              title: 'Carpet and Upholstery cleaning',
              text: 'Cleaning with professional equipment that effectively and safely removes not only abrasive dirt from your carpet, but also unpleasant stains from drinks, chewing gum, oil, and spilled food. Our quick drying process will make your carpet and upholstery ready to use within 2 hours.'
            },
          ]
        }
      },
      ru: {
        translation: {
          "about-us-1": `Компания была основана Яном Пузоном, который имел большой опыт работы в компаниях по борьбе с вредителями в ОАЭ. Изначально она называлась British Pest Control и была первой и единственной частной компанией по борьбе с вредителями в Азербайджане. Ян Пузон скончался в 2000 году, и с этого времени компания была переименована в Puzon Co в честь Яна Пузона.`,
          "about-us-2": `Puzon Co специализируется на услугах по борьбе с вредителями в коммерческих, промышленных и жилых помещениях в Азербайджане. Концепция обслуживания сочетает в себе качество, ценовую политику, комплексный подход к борьбе с вредителями, используя безопасную для экологии препараты.`,
          "about-us-3": `Мы применяем все необходимые стандарты ВОЗ и HACCP (анализ рисков и критические контрольные точки). Для сохранения экологически чистой среды нами применяется система Интеграционного контроля над размножением вредителей и возможные методики для уменьшения популяции вредителей.`,
          "about-us-4": `На протяжении более чем 25 лет компания Puzon Co стремится обеспечить полное удовлетворение потребностей клиентов. Это включает быстрое время отклика, экологически ответственные программы, детальное и индивидуальное обслуживание.`,
          "about-us-5": `Наши услуги доступны для вас 24/7. С Puzon Co вы полностью защищены, все что делают для вас наши опытные и отзывчивые операторы по борьбе с вредителями, качественно и гарантировано.`,
          "our-customers": `Наши уважаемые клиенты`,
          "our-certificates": `Наши сертификаты`,
          "quote-1": `Совершенство - это норма во всем, что мы делаем.`,
          "quote-2": `Честность и порядочность лежат в основе всех наших действий.`,
          "quote-3": `Дезинсекция`,
          "our-services": `Область наших услуг`,
          services: [
            {
              title: 'Дезинсекция',
              text: 'Уничтожение и профилактические мероприятия по борьбе со всеми видами насекомых и членистоногих вредителей: тараканов, муравьев, жуков, ос, блох, мух, комаров, клопов, пауков, скорпионов.'
            },
            {
              title: 'Дератизация',
              text: 'Комплексные меры по уничтожению грызунов - источников инфекционных заболеваний и приносящих огромные экономический ущерб.'
            },
            {
              title: 'Дезинфекция Covid 19',
              text: 'Комплекс мероприятий направленный на уничтожения бактерий и вирусов, таких как SARS- CoV2(COVID 19) и H1N1(грипп), и разрушений токсинов на объектах внешней среды.'
            },
            {
              title: 'Фумигация',
              text: 'Процесс использования химического дыма или пара для уничтожения различных вредителей и грызунов на складах и промышленных предприятиях. Рекомендуется компаниям, занимающимся мировой торговлей.'
            },
            {
              title: 'Контроль над Змеями',
              text: 'Профилактическая мероприятия по вывозу змей и других рептилий.'
            },
            {
              title: 'Контроль над Дикими Животными',
              text: 'Вывоз любых видов животных вызывающих проблемы, опасность и повреждение имущества.'
            },
            {
              title: 'Чистка Ковров и Мягкой Мебели',
              text: 'Читка с использованием профессионального оборудования, которое эффективно и безопасно удаляет не только абразивные загрязнения с вашего ковра, но и неприятные пятна от напитков, жевательной резинки, масла и пролитой пищи. Благодаря нашему быстрому высыханию ваш ковер и обивка будут готовы к использованию в течение 2 часов.'
            },
          ]
        }
      },
      az: {
        translation: {
          "about-us-1": `Şirkət BƏƏ-də zərərvericilərə qarşı mübarizə şirkətlərində böyük təcrübəyə malik olan Yann Puzon tərəfindən təsis edilib. O, əvvəlcə British Pest Control adlanırdı və Azərbaycanda ilk və yeganə özəl zərərvericilərə qarşı mübarizə şirkəti idi. Jan Puzon 2000-ci ildə vəfat etdi və o vaxtdan bəri şirkət Jan Puzonun şərəfinə Puzon Co adlandırılır.`,
          "about-us-2": `Puzon Co Azərbaycanda ticarət, sənaye və yaşayış binaları üçün zərərvericilərə qarşı mübarizə xidmətləri üzrə ixtisaslaşmışdır. Xidmət konsepsiyası ekoloji təmiz məhsullardan istifadə etməklə keyfiyyət, qiymət siyasəti, zərərvericilərə qarşı mübarizəyə yönəlmiş yanaşmanı özündə birləşdirir.`,
          "about-us-3": `Biz bütün zəruri ÜST və Təhlükə Təhlili və Kritik Nəzarət Nöqtələri (НАССР) standartlarını tətbiq edirik. Ekoloji cəhətdən təmiz mühiti qorumaq üçün biz zərərvericilərin çoxalmasına inteqrasiya nəzarəti sistemindən və zərərvericilərin sayının azaldılması üçün mümkün üsullardan istifadə edirik.`,
          "about-us-4": `25 ildən artıqdır ki, Puzon Co tam müştəri məmnuniyyətini təmin etməyə davam edir. Buraya sürətli cavab, ekoloji cəhətdən məsuliyyətli proqramlar, ətraflı və şəxsə özəl xidmət daxildir.`,
          "about-us-5": `Biz 24/7 xidmətinizdəyik. Puzon Co ilə siz tam qoruma altındasınız, təcrübəli və xidmətə hazır zərərvericilərə qarşı mübarizə operatorlarımızın sizə göstərdikləri xidmətlər isə yüksək keyfiyyətli və zəmanətlidir.`,
          "our-customers": `Dəyərli müştərilərimiz`,
          "our-certificates": `Sertifikatlarımız`,
          "quote-1": `Mükəmməllik gördüyümüz hər bir işdə normadır.`,
          "quote-2": `Dürüstlük və səmimiyyət atdığımız bütün addımların əsasını təşkil edir.`,
          "quote-3": `Jan Puzon`,
          "our-services": `Xidmətlərimiz`,
          services: [
            {
              title: 'Dezinseksiya',
              text: 'Bütün növ həşəratlara və buğumayaqlı zərərvericilərə qarşı mübarizə aparmaq üçün məhvetmə və profilaktik tədbirlər: tarakanlar, qarışqalar, böcəklər, arılar, birələr, milçəklər, ağcaqanadlar, bitlər, hörümçəklər, əqrəblər.'
            },
            {
              title: 'Deratizasiya',
              text: 'Yoluxucu xəstəliklərin mənbəyi olan və böyük iqtisadi ziyan vuran gəmiricilərin məhv edilməsi üzrə kompleks tədbirlər.'
            },
            {
              title: 'Covid 19 qarşı dezinfeksiya',
              text: 'SARS-CoV2 (COVID 19) və H1N1 (qrip) kimi bakteriya və virusları və ətraf mühit obyektlərindəki toksinləri məhv etməyə yönəlmiş tədbirlər kompleksi.'
            },
            {
              title: 'Fumiqasiya',
              text: 'Anbarlarda və sənaye müəssisələrində müxtəlif zərərvericiləri və gəmiriciləri məhv etmək üçün kimyəvi tüstüdən və ya buxardan istifadə prosesi. Qlobal ticarətlə məşğul olan şirkətlər üçün tövsiyə olunur.'
            },
            {
              title: 'İlanlara nəzarəti',
              text: 'İlanların və digər sürünənlərin çıxarılması üçün profilaktik tədbirlər.'
            },
            {
              title: 'Vəhşi heyvanlara nəzarət',
              text: 'Problem, təhlükə və əmlaka ziyan vuran istənilən növ heyvanların çıxarılması.'
            },
            {
              title: 'Xalçaların və yumşaq mebellərin əşyalarının təmizlənməsi',
              text: 'Xalçanızdakı tək köhnə ləkələri deyil, həm də içki, saqqız, yağ və tökülən qida ləkələrini effektiv və təhlükəsiz şəkildə təmizləyən peşəkar avadanlıqdan istifadə etməklə təmizlik üsulu. Tez qurutma sayəsində xalçanız və üzlüyünüz 2 saat ərzində istifadəyə hazır olacaq.'
            },
          ]
        }
      }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });